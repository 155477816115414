import {
  ClipboardDocumentListIcon,
  Cog8ToothIcon,
} from "@heroicons/vue/24/outline";

const navigation = [
  // { name: 'Dashboard', href: '/', icon: Squares2X2Icon },
  {
    name: "Prescriptions",
    href: "/prescriptions",
    icon: ClipboardDocumentListIcon,
  },
  { name: "Settings", href: "/settings", icon: Cog8ToothIcon },
];

const toolsNavigation = [
  // { name: "EPOS Export", href: "/epos-export", initial: "EE" },
  { name: "Reporting", href: "/reporting", initial: "RE" },
  // { name: "Messaging", href: "/messaging", initial: "ME" },
  // { name: "Tags", href: "/tags", initial: "Tg" },
];
export { navigation, toolsNavigation };
